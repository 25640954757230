import { useRouter } from 'next/router';
import { extractFromQuery } from '../extract-from-query/extract-from-query';
import {
  errorToast,
  infoToast,
  successToast,
  warningToast,
} from '../../components/toast/toast';
import { useEffect } from 'react';

interface Message {
  [key: string]: string;
}

/**
 * Displays a toast when &error=... &info=... &success=... or &warning=...
 * is present in the query string. A custom map can be provided for custom
 * messages. There is a single default error message which is &error=unknown.
 *
 * @param messages - messages map with key and message to be displayed
 */
export function usePageToast(messages?: Message) {
  const router = useRouter();
  const error = extractFromQuery(router.query, 'error');
  const success = extractFromQuery(router.query, 'success');
  const info = extractFromQuery(router.query, 'info');
  const warning = extractFromQuery(router.query, 'warning');

  /** Effects */
  useEffect(
    () => onDisplayToast(error, info, success, warning),
    [error, info, success, warning],
  );

  /**
   * Displays message in the correct toast format
   *
   * @param error - error query param
   * @param info - info query param
   * @param success - success query param
   * @param warning - warning query param
   */
  function onDisplayToast(
    error: string | null,
    info: string | null,
    success: string | null,
    warning: string | null,
  ) {
    if (error) {
      const errorMessage = getMessage(error);
      errorToast(errorMessage, { onClose: () => onClearQueryParam('error') });
    } else if (info) {
      const infoMessage = getMessage(info);
      infoToast(infoMessage, { onClose: () => onClearQueryParam('info') });
    } else if (success) {
      const successMessage = getMessage(success);
      successToast(successMessage, {
        onClose: () => onClearQueryParam('success'),
      });
    } else if (warning) {
      const warningMessage = getMessage(warning);
      warningToast(warningMessage, {
        onClose: () => onClearQueryParam('warning'),
      });
    }
  }

  /**
   * Clears params from query string to prevent it from being
   * displayed again (i.e. on page refresh)
   *
   * @param queryParam - query param to clear
   */
  function onClearQueryParam(queryParam: string): void {
    const paramToReplace = new RegExp(queryParam + '=[^&]*');
    const path = router.asPath.replace(paramToReplace, '');
    // Using shallow as 'true' will prevent a data refetch for the page
    router.replace(path, undefined, { shallow: true });
  }

  /**
   * Gets a readable message from the query param
   *
   * @param key - message key
   * @returns string
   */
  function getMessage(key: string | undefined): string {
    // Default unknown error available to all pages
    if (key === 'unknown') {
      return 'An unknown error occurred with your request. Please try again or contact support: support@qeepsake.com';
    } else if (key) {
      // Looks for custom message, provided as params from hook
      const message = messages?.[key];
      // If a custom message is available for this key, return it
      if (message) return message;
      // return the key which is can also be the message
      else return key;
    }

    return '';
  }
}
