import React from 'react';
import { Field, useField } from 'formik';
import { FormLabel } from '../typography/form-label';
import { FormError } from '../errors/form-errors/form-error';

export interface TextFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * Should the input be full width
   */
  block?: boolean;

  /**
   * Name of the field in Formik, this is to automatically
   * connect the input to Formik events and values.
   */
  name: string;

  /**
   * Overwrites styles of the input with
   * the additionalClasses property, if  provided
   */
  additionalClasses?: string;

  /**
   * Label text for input
   * if empty label will not show
   */
  label?: string;

  /**
   * Overwrites styles of the label with
   * the additionalClasses property, if provided
   */
  additionalLabelClasses?: string;
}

export const TextField = ({
  block = false,
  name,
  additionalClasses,
  label,
  additionalLabelClasses,
  ...props
}: TextFieldProps) => {
  const [field, meta] = useField(name);
  const isBlock = block ? 'w-full' : '';
  const isError = meta.error && meta.touched;
  const invalid = isError ? 'border-red-500 border-r-8' : 'border-gray-500';
  const fieldProps = {
    ...props,
    name: name,
    className: [
      isBlock,
      invalid,
      'px-4 h-13 rounded-md border-gray-500 border-0.25 placeholder:text-gray-300',
      additionalClasses,
    ].join(' '),
  };

  return (
    <>
      {label && (
        <FormLabel label={label} additionalClasses={additionalLabelClasses} />
      )}
      <Field {...fieldProps} as="input" />

      <FormError name={field.name} />
    </>
  );
};
