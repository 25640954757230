import React from 'react';
import { useRouter } from 'next/router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Head from 'next/head';
import { PasswordField } from '../components/password-field/password-field';
import { TextField } from '../components/text-field/text-field';
import { Button } from '../components/buttons/button';
import { useTranslation } from 'react-i18next';
import { Header } from '../containers/header/header';
import { ResultAsync } from 'neverthrow';
import { ErrorResult } from '../configuration/error-result';
import { useRequestLoader } from '../utils/use-request-loader/use-request-loader';
import { errorToast } from '../components/toast/toast';
import { Footer } from '../components/footer';
import { getAuthToken } from '../utils/get-auth-token/get-auth-token';
import { ApolloError, FetchResult, gql, useMutation } from '@apollo/client';
import {
  LoginMutation,
  LoginMutationVariables,
} from '../graphql/generated/types';
import { usePageToast } from '../utils/use-page-toast/use-page-toast';
import { HeaderText } from '../components/typography/header-text';
import { CaptionText } from '../components/typography/caption-text';
import { Anchor } from '../components/typography/anchor';
import { setAuthToken } from '../utils/set-auth-token/set-auth-token';
import { deleteCookie, getCookie } from 'cookies-next';

interface SignInFormValues {
  email: string | null | undefined;
  password: string | null | undefined;
}

const LoginMutationGql = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      authentication_token
    }
  }
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Email is not the correct format.')
    .required('Email is required.')
    .typeError('Email is not the correct format.'),
  password: Yup.string()
    .required('Password is required.')
    .typeError('Password is not the correct format.'),
});

export async function getServerSideProps({
  req,
  res,
  query,
}: ServerSideProps<SSPDefaultOptions>): Promise<
  SSPAsyncReturnType<Record<string, never>>
> {
  const authToken = getAuthToken(req, res, query);
  const queryParamString = new URLSearchParams(query).toString();

  if (authToken) {
    return {
      redirect: {
        destination: `/dashboard?${queryParamString}`,
        permanent: false,
      },
    };
  }

  return {
    props: {},
  };
}

export default function LoginPage() {
  const router = useRouter();
  const { t } = useTranslation();
  const [startLoginRequest, isLoginRequestLoading] = useRequestLoader<
    FetchResult<LoginMutation>,
    ApolloError
  >(handleLoginRequestSuccess, handleLoginRequestFailed);
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(
    LoginMutationGql,
  );
  const initialValues: SignInFormValues = {
    email: undefined,
    password: undefined,
  };
  // Listen for the forgot-password query param and display toast if available
  usePageToast({
    forgot_password_success: t('forgot_password_success'),
  });

  return (
    <div className="flex flex-col h-screen justify-between">
      <Head>
        <title>Qeepsake - Login</title>
      </Head>

      <Header viewer={null} source={null} />

      <div className="container mx-auto mb-auto xs:w-96">
        <HeaderText size="h2" center={true} additionalClasses="mb-6 mt-16">
          Welcome Back!
        </HeaderText>

        <Formik
          initialValues={initialValues}
          onSubmit={handleLoginRequest}
          validationSchema={validationSchema}
        >
          {({ handleSubmit }) => (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col px-2"
              data-testid="loginForm"
            >
              <TextField
                name="email"
                type="email"
                placeholder="Email"
                block={true}
                data-testid="emailInput"
              />
              <PasswordField
                name="password"
                placeholder="Password"
                block={true}
                additionalClasses="mt-3"
                data-testid="passwordInput"
              />

              <Anchor
                text="Forgot password?"
                additionalClasses="ml-2 mb-6 mt-3"
                onClick={() => router.push('/forgot-password')}
                data-testid="forgotPasswordLink"
              />

              <Button
                label="LOG IN"
                type="submit"
                block={true}
                additionalClasses="mt-4 mx-auto w-full"
                disabled={isLoginRequestLoading}
                data-testid="loginButton"
              />

              <CaptionText center={true} additionalClasses="mb-6 mt-8">
                Don’t have an account?{' '}
                <Anchor
                  text="Sign up here."
                  onClick={() =>
                    router.push('/onboarding/subscription-plan-select')
                  }
                  data-testid="signUpLink"
                />
              </CaptionText>
            </form>
          )}
        </Formik>
      </div>
      <Footer />
    </div>
  );

  /**
   * Handles login request
   *
   * @param values - form values
   */
  function handleLoginRequest(values: SignInFormValues) {
    const email = values.email;
    const password = values.password;

    // Handle empty email
    if (!email) {
      const message = t('email_required');
      errorToast(message);
      return;
    }
    // Handle empty password
    if (!password) {
      const message = t('password_required');
      errorToast(message);
      return;
    }

    startLoginRequest(() => {
      const requestPromise = login({
        variables: {
          email,
          password,
        },
      });
      const requestFn = ResultAsync.fromPromise(requestPromise, (e) =>
        ErrorResult.create({
          name: 'LOGIN_REQUEST_FAILED',
          message: 'Login request failed',
          error: e,
          context: {
            extra: {
              email,
            },
          },
        }),
      );

      return requestFn;
    });
  }

  /**
   * Handles successful login request
   *
   * @param response - response from login request
   */
  function handleLoginRequestSuccess(response: FetchResult<LoginMutation>) {
    const authToken = response?.data?.login?.authentication_token;
    if (authToken) {
      setAuthToken(authToken);
    }

    const forwardUrl = getCookie('forward_url');

    if (forwardUrl && typeof forwardUrl === 'string') {
      deleteCookie('forward_url');
      router.push(forwardUrl);
    } else {
      router.push('/dashboard');
    }
  }

  /**
   * Handles failed login request
   *
   * @param e - error result
   */
  function handleLoginRequestFailed(
    e: ErrorResult<'LOGIN_REQUEST_FAILED', ApolloError>,
  ) {
    const message = e.error.message;

    if (message === 'LOGIN_OR_PASSWORD_INVALID') {
      const message = t('login_credentials_incorrect');
      errorToast(message);
    } else if (message === 'LOGIN_LAST_ATTEMPT') {
      const message = t('last_login_attempt');
      errorToast(message);
    } else if (message === 'USER_LOCKED') {
      const message = t('user_locked');
      errorToast(message);
    } else {
      const message = t('unable_to_complete_request');
      errorToast(message);
    }
  }
}
