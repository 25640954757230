import { setCookie } from 'cookies-next';

/**
 * Sets the auth token cookie client side
 *
 * @param authToken - auth token to set
 */
export function setAuthToken(authToken: string): void {
  setCookie('auth_token', authToken, {
    path: '/',
    maxAge: 86400, // 86400 = 24 hours
  });
}
