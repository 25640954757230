import React from 'react';
import { ErrorMessage } from 'formik';

export interface FormErrorProps {
  /**
   * A field's name in Formik state.
   */
  name: string;

  /**
   * Either a React component or the name of an HTML element to render.
   * If not specified, <ErrorMessage> will just return a string.
   * Defaults to 'div'
   */
  component?: string;

  /**
   * Overwrites styles of the error message with
   * the additionalClasses property, if provided
   */
  additionalClasses?: string;

  /**
   * TODO Not implement
   * A function that returns a valid React element.
   * Will only be called when the field has been touched and an error exists.
   */
  //children?: ((message: string) => React.ReactNode)

  /**
   * TODO Not implemented
   * A function that returns a valid React element.
   * Will only be called when the field has been touched and an error exists.
   */
  //render?: (error: string) => React.ReactNode;
}

export const FormError = ({
  name,
  component = 'div',
  additionalClasses,
  ...props
}: FormErrorProps) => {
  return (
    <ErrorMessage
      name={name}
      component={component}
      className={['text-red-500 text-sm ml-2 mt-1', additionalClasses].join(
        ' ',
      )}
      {...props}
    ></ErrorMessage>
  );
};
