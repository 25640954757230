import React from 'react';
import { BodyText } from './body-text';

export interface FormLabelProps {
  /**
   * Label text
   */
  label: string;
  /**
   * Overwrites styles of the label with
   * the additionalClasses property, if provided
   */
  additionalClasses?: string;
}

export const FormLabel = ({
  label,
  additionalClasses,
  ...props
}: FormLabelProps) => {
  return (
    <BodyText
      size="b2"
      additionalClasses={[
        'pt-2 font-sohne font-light text-sm',
        additionalClasses,
      ].join(' ')}
      {...props}
    >
      {label}
    </BodyText>
  );
};
