import React, { useState } from 'react';
import { TextField, TextFieldProps } from '../text-field/text-field';

export const PasswordField = ({
  name,
  additionalClasses,
  label,
  additionalLabelClasses,
  ...props
}: TextFieldProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible((prevState) => !prevState);
  }

  return (
    <div className="relative">
      <TextField
        {...props}
        block={true} // password field have to be block for the visibility icon to work
        name={name}
        label={label}
        additionalClasses={additionalClasses}
        additionalLabelClasses={additionalLabelClasses}
        type={isPasswordVisible ? 'text' : 'password'}
      />
      <div
        className="absolute h-11 inset-y-1 right-4 flex items-end"
        onClick={togglePasswordVisibility}
      >
        {isPasswordVisible ? (
          <img
            src="/assets/icons/password-closed-eye.png"
            className="cursor-pointer w-7 object-contain"
            alt="Hide Password"
          />
        ) : (
          <img
            src="/assets/icons/password-open-eye.png"
            className="cursor-pointer w-7 object-contain"
            alt="Show Password"
          />
        )}
      </div>
    </div>
  );
};
