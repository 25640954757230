import React from 'react';

interface CaptionTextProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  /**
   * Pass in additional tailwind classes.
   * To override font size you have to
   * set the size property to custom
   */
  additionalClasses?: string;
  /**
   * Text string for caption
   */
  children: React.ReactNode;
  /**
   * Center text horizontally
   */
  center?: boolean;
}

export const CaptionText = ({
  center = false,
  additionalClasses,
  children,
  ...props
}: CaptionTextProps) => {
  const isCenter = center ? 'text-center' : '';

  return (
    <div
      className={[
        isCenter,
        'font-sohne text-black text-xs',
        additionalClasses,
      ].join(' ')}
      {...props}
    >
      {children}
    </div>
  );
};
