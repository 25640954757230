import { ParsedUrlQuery } from 'querystring';

/**
 * Extracts a value from a query string.
 *
 * @param query - The query string to extract from
 * @param key - The key to extract
 * @returns extracted value
 */
export function extractFromQuery(
  query: ParsedUrlQuery,
  key: string,
  type?: 'string',
): string;
export function extractFromQuery(
  query: ParsedUrlQuery,
  key: string,
  type?: 'number',
): number | undefined;
export function extractFromQuery(
  query: ParsedUrlQuery,
  key: string,
  type?: 'boolean',
): boolean;
export function extractFromQuery(
  query: ParsedUrlQuery,
  key: string,
  type: 'string' | 'number' | 'boolean' = 'string',
): string | number | boolean | undefined {
  const result = Array.isArray(query[key])
    ? query[key]?.[0]
    : (query[key] as string);

  // Catches instances we get an 'undefined' string, which can cause issues
  if (result === 'undefined') return undefined;

  // Converts the query string to 'number' or 'boolean'
  if (type === 'number') {
    // We can't parse 'undefined'
    if (result === undefined) return undefined;
    // Parse result into a Number
    return Number(result);
  } else if (type === 'boolean') {
    return result?.toLowerCase?.() === 'true';
  }

  // Return the result
  return result;
}
