/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResultAsync } from 'neverthrow';
import { useContext, useState } from 'react';
import { ErrorResult } from '../../configuration/error-result';
import { PageLoadingContext } from '../../configuration/page-loading-context';

type UseLoadingReturnType<Result = unknown, Error = any, Extra = unknown> = [
  (
    fn: () => ResultAsync<Result, ErrorResult<any, Error>>,
    extra?: Extra,
  ) => void,
  boolean,
];

/**
 * Handles the pages loading state for requests. This includes showing a full page loading indicator and
 * allows you to define success and error callbacks.
 *
 * @example
 * const [startRequest, isLoading] = useRequestLoader(optionalSuccessCallback, optionalErrorCallback);
 *
 * // Wrapping the neverthrow function like so, will automatically handle the loading state
 * startRequest(myNeverthrowFunction);
 *
 * @param handleSuccess - optional success callback to handle success of request
 * @param handleError - optional error callback to handle error of request
 * @returns
 */
export function useRequestLoader<
  Result = unknown,
  Error = unknown,
  Extra = unknown,
>(
  handleSuccess?: (result: Result, extra?: Extra) => void,
  handleError?: (error: ErrorResult<any, Error>, extra?: Extra) => void,
): UseLoadingReturnType<Result, Error, Extra> {
  const [isLoading, setIsLoading] = useState(false);
  // Manages a full screen loading indicator
  const { showLoading, hideLoading } = useContext(PageLoadingContext);

  return [startRequest, isLoading];

  function startRequest(
    fn: () => ResultAsync<Result, ErrorResult<any, Error>>,
    extra?: Extra,
  ): void {
    setIsLoading(true);
    showLoading();

    // Runs the request and matches to success or failure callbacks
    fn().match(
      (result) => onRequestSuccess(result, extra),
      (error) => onRequestFailure(error, extra),
    );
  }

  /**
   * Called when saving succeeds
   *
   * @param result - the result of the request
   * @param extra - the extra passed to the success handler
   */
  function onRequestSuccess(result: Result, extra?: Extra) {
    setIsLoading(false);
    hideLoading();
    if (handleSuccess) {
      handleSuccess(result, extra);
    }
  }

  /**
   * Called when saving fails
   *
   * @param error - the error that was thrown
   * @param extra - the extra passed to the failure handler
   */
  function onRequestFailure(error: ErrorResult<any, Error>, extra?: Extra) {
    hideLoading();
    setIsLoading(false);
    if (handleError) {
      handleError(error, extra);
    }
  }
}
